<template>
  <div>
    <b-modal id="modal-update-balance-type" cancel-variant="outline-secondary" :ok-title="$t('Edit')" :cancel-title="$t('Close')"
      ok-variant="success" centered modal-class="modal-success" :title="$t('Update A Balance Type')" @ok="confirmAdd"
      @hidden="resetModal()" size="lg">
      <!-- form -->
      <validation-observer ref="updateBalanceTypeModalRef">
        <b-form method="POST" class="auth-reset-password-form mt-2" @submit.prevent="validationForm">
          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="name">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Name") }}</label>
                <validation-provider #default="{ errors }" name="name" rules="required">
                  <b-form-input id="name" v-model="newBalanceType.name" rows="4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="name_en">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Name_en") }}</label>
                <validation-provider #default="{ errors }" name="name_en" rules="required">
                  <b-form-input id="name_enkey" v-model="newBalanceType.name_en" rows="4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="wallet_name">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Wallet_name") }}</label>
                <validation-provider #default="{ errors }" name="wallet_name" rules="required">
                  <b-form-input id="wallet_name" v-model="newBalanceType.wallet_name" rows="4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="wallet_name_en">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Wallet_name_eney") }}</label>
                <validation-provider #default="{ errors }" name="wallet_name_en" rules="required">
                  <b-form-input id="wallet_name_en" v-model="newBalanceType.wallet_name_en" rows="4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="key">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Key") }}</label>
                <validation-provider #default="{ errors }" name="key" rules="required">
                  <b-form-input id="key" v-model="newBalanceType.key" rows="4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="type">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Type") }}</label>
                <validation-provider #default="{ errors }" name="type" rules="required">
                  <v-select
                    v-model="newBalanceType.type"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="typeOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    placeholder="Select type"
                    label="label"
                    input-id="type"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="img">
                <label class="mb-1"> {{ $t("Image") }} <span class="text-secondary">(Không > 10Mb)</span></label>
                <validation-provider #default="{ errors }" name="img">
                  <b-form-file
                    v-model="newBalanceType.img"
                    input-id="logo"
                    accept="image/jpeg,image/png,image/gif,image/svg,image/jpg"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="bonus">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Bonus") }}</label>
                <validation-provider #default="{ errors }" name="bonus" rules="required">
                  <b-form-input id="bonus" v-model="newBalanceType.bonus" rows="4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="description">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Description") }}</label>
                <validation-provider #default="{ errors }" name="description" rules="required">
                  <b-form-input id="description" v-model="newBalanceType.description" rows="4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="order">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Order") }}</label>
                <validation-provider #default="{ errors }" name="order" rules="required">
                  <b-form-input id="order" v-model="newBalanceType.order" rows="4"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        
          <b-row>
            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="status">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Status") }}</label>
                <validation-provider #default="{ errors }" name="status" rules="required">
                  <v-select
                    v-model="newBalanceType.status"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="statusOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    placeholder="Select type"
                    label="text"
                    input-id="status"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col cols="12" md="6" lg="6" sm="6" class="mb-2 ipad">
              <b-form-group label-for="is_play_for_fun">
                <label class="mb-1"><span class="text-danger">*</span> {{ $t("Is_play_for_fun") }}</label>
                <validation-provider #default="{ errors }" name="is_play_for_fun" rules="required">
                  <v-select
                    v-model="newBalanceType.is_play_for_fun"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="yesNoOptions"
                    :reduce="(val) => val.value"
                    :clearable="false"
                    placeholder="Select type"
                    label="text"
                    input-id="is_play_for_fun"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full.esm";
import {
  BLink,
  BRow,
  BCol,
  BTabs,
  BTab,
  BForm,
  BFormGroup,
  BFormInput,
  BFormFile,
  BButton,
  BInputGroup,
  BInputGroupPrepend,
  BModal,
  VBModal,
  BInputGroupAppend,
  BFormTextarea,
  BBadge,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import router from "@/router";

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BTabs,
    BTab,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BModal,
    BFormTextarea,
    BBadge,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
    vSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  props: {
    balanceTypeDetail: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      newBalanceType: {
        name: '',
        name_en: '',
        wallet_name: '',
        wallet_name_en: '',
        key: '',
        type: '',
        img: '',
        bonus: '',
        description: '',
        status: '',
        order: '',
        is_play_for_fun: '',
      },
    };
  },
  watch: {
    balanceTypeDetail: {
      handler(newVal) {
        if (newVal) {
          this.newBalanceType = {
            id: newVal.id,
            name: newVal.name,
            name_en: newVal.name_en,
            wallet_name: newVal.wallet_name,
            wallet_name_en: newVal.wallet_name_en,
            key: newVal.key,
            type: newVal.type,
            img: newVal.img,
            bonus: newVal.bonus,
            description: newVal.description,
            status: newVal.status,
            order: newVal.order,
            is_play_for_fun: newVal.is_play_for_fun,
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    validationForm() {
      this.$refs.updateBalanceTypeModalRef.validate().then((success) => {
        if (success) {
          this.updateParam(this.newBalanceType);
        }
      });
    },
    async updateParam(newBalanceType) {
      await this.$store
        .dispatch("setting/updateBalanceType", newBalanceType)
        .then((response) => {
          if (response.status === 200) {
            if (response.data.code === "00") {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message || "success",
                  icon: "CheckIcon",
                  variant: "success",
                },
              });
              this.$nextTick(() => {
                setTimeout(() => {
                  this.$bvModal.hide("modal-update-balance-type");
                  this.resetModal();
                }, "500");
              });
              this.$emit("refetch-data");
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: response.data.message,
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                },
              });
            }
          }
        });
    },
    resetModal() {
      this.newBalanceType = {
        name: '',
        name_en: '',
        wallet_name: '',
        wallet_name_en: '',
        key: '',
        type: '',
        img: '',
        bonus: '',
        description: '',
        status: '',
        order: '',
        is_play_for_fun: '',
      };
      this.$emit("update:balanceTypeDetail", null);
    },
    confirmAdd(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.validationForm();
    },
  },
  setup() {
    const typeOptions = [
      { label: "Main", value: 1 },
      { label: "Game", value: 2 },
      { label: "Bous", value:3 },
    ];
    const statusOptions = [
      { text: "Active", value: 1 },
      { text: "Inactive", value: 2 },
    ];
    const yesNoOptions = [
      { text: "Có", value: 1 },
      { text: "Không", value: 2 },
    ]
    return {typeOptions, statusOptions, yesNoOptions};
  },
};
</script>