var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-add-new-balance-type","cancel-variant":"outline-secondary","ok-title":_vm.$t('Add'),"cancel-title":_vm.$t('Close'),"ok-variant":"success","centered":"","modal-class":"modal-success","title":_vm.$t('Add A New Param'),"size":"lg"},on:{"ok":_vm.confirmAdd,"hidden":function($event){return _vm.resetModal()}}},[_c('validation-observer',{ref:"addNewBalanceTypeModalRef"},[_c('b-form',{staticClass:"auth-reset-password-form mt-2",attrs:{"method":"POST"},on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"name"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Name")))]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newBalanceType.name),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "name", $$v)},expression:"newBalanceType.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"name_en"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Name_en")))]),_c('validation-provider',{attrs:{"name":"name_en","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name_enkey","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newBalanceType.name_en),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "name_en", $$v)},expression:"newBalanceType.name_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"wallet_name"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Wallet_name")))]),_c('validation-provider',{attrs:{"name":"wallet_name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wallet_name","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newBalanceType.wallet_name),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "wallet_name", $$v)},expression:"newBalanceType.wallet_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"wallet_name_en"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Wallet_name_eney")))]),_c('validation-provider',{attrs:{"name":"wallet_name_en","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"wallet_name_en","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newBalanceType.wallet_name_en),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "wallet_name_en", $$v)},expression:"newBalanceType.wallet_name_en"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"key"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Key")))]),_c('validation-provider',{attrs:{"name":"key","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"key","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newBalanceType.key),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "key", $$v)},expression:"newBalanceType.key"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"type"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Type")))]),_c('validation-provider',{attrs:{"name":"type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.typeOptions,"reduce":function (val) { return val.value; },"clearable":false,"placeholder":"Select type","label":"label","input-id":"type"},model:{value:(_vm.newBalanceType.type),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "type", $$v)},expression:"newBalanceType.type"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"img"}},[_c('label',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(_vm.$t("Image"))+" "),_c('span',{staticClass:"text-secondary"},[_vm._v("(Không > 10Mb)")])]),_c('validation-provider',{attrs:{"name":"img"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-file',{attrs:{"input-id":"logo","accept":"image/jpeg,image/png,image/gif,image/svg,image/jpg"},model:{value:(_vm.newBalanceType.img),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "img", $$v)},expression:"newBalanceType.img"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"bonus"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Bonus")))]),_c('validation-provider',{attrs:{"name":"bonus","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"bonus","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newBalanceType.bonus),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "bonus", $$v)},expression:"newBalanceType.bonus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"description"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Description")))]),_c('validation-provider',{attrs:{"name":"description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newBalanceType.description),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "description", $$v)},expression:"newBalanceType.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"order"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Order")))]),_c('validation-provider',{attrs:{"name":"order","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"order","rows":"4","dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'},model:{value:(_vm.newBalanceType.order),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "order", $$v)},expression:"newBalanceType.order"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"status"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Status")))]),_c('validation-provider',{attrs:{"name":"status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.statusOptions,"reduce":function (val) { return val.value; },"clearable":false,"placeholder":"Select type","label":"text","input-id":"status"},model:{value:(_vm.newBalanceType.status),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "status", $$v)},expression:"newBalanceType.status"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"mb-2 ipad",attrs:{"cols":"12","md":"6","lg":"6","sm":"6"}},[_c('b-form-group',{attrs:{"label-for":"is_play_for_fun"}},[_c('label',{staticClass:"mb-1"},[_c('span',{staticClass:"text-danger"},[_vm._v("*")]),_vm._v(" "+_vm._s(_vm.$t("Is_play_for_fun")))]),_c('validation-provider',{attrs:{"name":"is_play_for_fun","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.yesNoOptions,"reduce":function (val) { return val.value; },"clearable":false,"placeholder":"Select type","label":"text","input-id":"is_play_for_fun"},model:{value:(_vm.newBalanceType.is_play_for_fun),callback:function ($$v) {_vm.$set(_vm.newBalanceType, "is_play_for_fun", $$v)},expression:"newBalanceType.is_play_for_fun"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }